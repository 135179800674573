<template>
  <div class="main">
    <h1>Download</h1>
    <div class="table-class">
      <div class="table-start">
        <div class="search-container">
          <input type="text" placeholder="Search.." v-model="search" />
          <div class="search-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#7A869A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.9999 21L16.6499 16.65"
                stroke="#7A869A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <ul>
            <li>
              <b-form-select
                v-model="selected"
                class="mb-3"
                @change="setFilterValue(search)"
              >
                <b-form-select-option :value="null"
                  >Choices</b-form-select-option
                >
                <b-form-select-option value="originalFileName"
                  >File name</b-form-select-option
                >
                <b-form-select-option value="noOfRowsProcessed"
                  ># contect</b-form-select-option
                >
              </b-form-select>
            </li>
            <li>
              <!-- <a href=""><button>Find</button></a> -->
            </li>
          </ul>
        </div>

        <div class="main-table">
          <table v-if="finalFileData.length <= 0" class="first-table">
            <tr>
              <th
                v-for="(heading, index) in tableHeader"
                :key="index"
                @click="sort('heading')"
              >
                {{ heading }}
              </th>
            </tr>
            <tr>
              <td colspan="12" class="text-center">
                No Data Found
              </td>
            </tr>
          </table>

          <table v-else class="first-table">
            <tr>
              <th v-for="(heading, index) in tableHeader" :key="index">
                {{ heading }}
              </th>
            </tr>

            <tr v-for="(data, index) in finalFileData" :key="index">
              <td>
                {{
                  data.originalFileName
                    ? data.originalFileName
                        .split(".")
                        .slice(0, -1)
                        .join(".")
                    : ""
                }}
              </td>
              <td>
                {{ data.startTime ? getDateInformat(data.startTime) : "" }}
              </td>
              <td>
                {{ data.startTime ? getformatDate(data.startTime) : "" }}
              </td>
              <td>
                {{
                  data.endTime
                    ? getDateInformat(data.endTime)
                    : "In processing.."
                }}
              </td>

              <td>
                {{
                  data.endTime ? getformatDate(data.endTime) : "In processing.."
                }}
              </td>
              <td>
                {{ data.noOfRowsProcessed ? data.noOfRowsProcessed : "" }}
              </td>
              <td>
                {{ data.jobType ? data.jobType : "" }}
              </td>
              <td v-if="data && data.endTime">
                <b-dropdown
                  id="dropdown-dropright"
                  dropright
                  :v-model="downloadFileType"
                  text="Drop-Right"
                  variant="primary"
                  class="m-2"
                  no-caret
                >
                  <template #button-content
                    ><svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.37082 15.7988H12.4833C12.7179 15.7989 12.9438 15.888 13.1152 16.0482C13.2866 16.2084 13.3909 16.4276 13.4069 16.6617C13.4229 16.8958 13.3494 17.1272 13.2014 17.3092C13.0533 17.4912 12.8417 17.6102 12.6092 17.6422L12.4833 17.6509H1.37082C1.1362 17.6508 0.910348 17.5617 0.738906 17.4015C0.567464 17.2413 0.463215 17.022 0.447223 16.788C0.431231 16.5539 0.504689 16.3224 0.652753 16.1404C0.800817 15.9584 1.01245 15.8394 1.24488 15.8074L1.37082 15.7988H12.4833H1.37082ZM6.80111 0.990821L6.92705 0.982178C7.15083 0.982187 7.36704 1.06323 7.53569 1.21031C7.70433 1.3574 7.81402 1.56058 7.84445 1.78228L7.85309 1.90822V11.4007L10.6374 8.61767C10.7944 8.4607 11.0026 8.36538 11.224 8.34906C11.4454 8.33274 11.6654 8.39652 11.8437 8.52877L11.9474 8.61767C12.1044 8.7747 12.1997 8.98289 12.216 9.20431C12.2324 9.42574 12.1686 9.64566 12.0363 9.82399L11.9474 9.92771L7.58146 14.2924C7.42466 14.4493 7.21679 14.5446 6.99564 14.5612C6.77449 14.5777 6.55475 14.5143 6.37637 14.3826L6.27265 14.2924L1.90669 9.92771C1.741 9.76247 1.64401 9.54064 1.63522 9.3068C1.62642 9.07297 1.70648 8.84447 1.85929 8.66726C2.01211 8.49005 2.22634 8.37725 2.45893 8.35156C2.69152 8.32587 2.9252 8.38919 3.11301 8.52877L3.21673 8.61767L6.00102 11.4032V1.90822C6.00103 1.68444 6.08207 1.46823 6.22915 1.29959C6.37624 1.13094 6.57942 1.02125 6.80111 0.990821L6.92705 0.982178L6.80111 0.990821Z"
                        fill="white"
                      /></svg
                  ></template>
                  <!-- <b-dropdown-item>
                    <span> File Type</span>
                  </b-dropdown-item> -->
                  <b-dropdown-item
                    class="fileType"
                    href="#"
                    @click="downloadFile(data.id, data.jobType, 'csv')"
                    >CSV</b-dropdown-item
                  >
                  <b-dropdown-item
                    class="fileType"
                    href="#"
                    @click="downloadFile(data.id, data.jobType, 'excel')"
                    >Excel</b-dropdown-item
                  >
                </b-dropdown>

                <!-- <a
                  ><button
                    click="downloadFile(data.id, data.originalFileName, 'excel')
                    "
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.37082 15.7988H12.4833C12.7179 15.7989 12.9438 15.888 13.1152 16.0482C13.2866 16.2084 13.3909 16.4276 13.4069 16.6617C13.4229 16.8958 13.3494 17.1272 13.2014 17.3092C13.0533 17.4912 12.8417 17.6102 12.6092 17.6422L12.4833 17.6509H1.37082C1.1362 17.6508 0.910348 17.5617 0.738906 17.4015C0.567464 17.2413 0.463215 17.022 0.447223 16.788C0.431231 16.5539 0.504689 16.3224 0.652753 16.1404C0.800817 15.9584 1.01245 15.8394 1.24488 15.8074L1.37082 15.7988H12.4833H1.37082ZM6.80111 0.990821L6.92705 0.982178C7.15083 0.982187 7.36704 1.06323 7.53569 1.21031C7.70433 1.3574 7.81402 1.56058 7.84445 1.78228L7.85309 1.90822V11.4007L10.6374 8.61767C10.7944 8.4607 11.0026 8.36538 11.224 8.34906C11.4454 8.33274 11.6654 8.39652 11.8437 8.52877L11.9474 8.61767C12.1044 8.7747 12.1997 8.98289 12.216 9.20431C12.2324 9.42574 12.1686 9.64566 12.0363 9.82399L11.9474 9.92771L7.58146 14.2924C7.42466 14.4493 7.21679 14.5446 6.99564 14.5612C6.77449 14.5777 6.55475 14.5143 6.37637 14.3826L6.27265 14.2924L1.90669 9.92771C1.741 9.76247 1.64401 9.54064 1.63522 9.3068C1.62642 9.07297 1.70648 8.84447 1.85929 8.66726C2.01211 8.49005 2.22634 8.37725 2.45893 8.35156C2.69152 8.32587 2.9252 8.38919 3.11301 8.52877L3.21673 8.61767L6.00102 11.4032V1.90822C6.00103 1.68444 6.08207 1.46823 6.22915 1.29959C6.37624 1.13094 6.57942 1.02125 6.80111 0.990821L6.92705 0.982178L6.80111 0.990821Z"
                        fill="white"
                      />
                    </svg></button
                ></a> -->
              </td>
              <td v-else class="proceed">
                <button
                  class="progress_btn btn-primary"
                  type="submit"
                  @click="openProgress()"
                >
                  <img src="@/assets/images/time-left.png" alt="process" />
                </button>
                <b-modal
                  v-model="isModalOpen"
                  title="EnrichMent Progress Status"
                  hide-footer
                >
                  <!-- Modal content goes here -->
                  <p>Progress of Enrichment</p>
                  <div class="progress-wrapper">
                    <div class="progress-bar">
                      <div
                        class="progress"
                        :style="{ width: progressPercentage + '%' }"
                      ></div>
                    </div>
                    <span>{{ progressPercentage }} %</span>
                  </div>
                </b-modal>
              </td>
              <td v-if="data.error === ''">
                <svg
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                    fill="#20B89E"
                  />
                </svg>
              </td>
              <td v-else>
                {{ data.error }}
              </td>
            </tr>
          </table>

          <div class="next-button">
            <div class="main-button">
              <b-pagination
                v-model="currentPage"
                @change="page($event)"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
          <div>
            <!-- <button>
            <router-link :to="{ name: 'dashboard' }"
              ><span>View Report</span></router-link
            >
          </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { error } from "logrocket";
import store from "../../../store/index";
import { loadingService } from "../../common/services/loading.service";
import moment from "moment";
export default {
  name: "file",
  computed: {},
  data: () => ({
    options: [
      { item: "A", name: "Option A" },
      { item: "B", name: "Option B" },
      { item: "D", name: "Option C", notEnabled: true },
      { item: { d: 1 }, name: "Option D" },
    ],
    isModalOpen: false,
    progressPercentage: 0,
    downloadFileType: "",
    search: "",
    selected: null,
    perPage: 10,
    currentPage: 1,
    fileData: [],
    finalFileData: [],
    searchFinalFileData: [],
    fullPage: false,
    rows: 0,
    tableHeader: [
      "File name",
      "Start Date",
      "Start time",
      "End Date",
      "End time",
      "Contect",
      "Type",
      "Processed file",
      "Status",
    ],
  }),
  methods: {
    setFilterValue(newValue) {
      this.searchFinalFileData = [];
      if (this.selected && newValue) {
        if (this.selected === "noOfRowsProcessed") {
          newValue = parseInt(newValue);
        }
        this.fileData.forEach((el) => {
          if (el[this.selected] === newValue) {
            this.searchFinalFileData.push(el);
          }
        });
        this.setFinalFileData(1);
      } else if (!newValue) {
        this.setFinalFileData(this.currentPage);
      } else {
        const regex = new RegExp(`${newValue}`, "g");
        this.finalFileData = [];
        for (let index = 0; index < this.fileData.length; index++) {
          const element = this.fileData[index];
          let newArray = Object.values(element);
          for (let index = 0; index < newArray.length; index++) {
            let newElement = newArray[index];
            newElement = newElement.toString();
            if (newElement && newElement.match(regex)) {
              if (
                this.searchFinalFileData &&
                this.searchFinalFileData.length == 0
              ) {
                this.searchFinalFileData.push(element);
              } else if (
                this.searchFinalFileData.filter((x) => x.id === element.id)
                  .length == 0
              ) {
                this.searchFinalFileData.push(element);
              }
            }
          }
        }

        this.setFinalFileData(1);
      }
    },
    page(event) {
      this.setFinalFileData(event);
      this.currentPage = event;
    },
    getDateInformat(d) {
      if (d) {
        let date = new Date(d);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let m, dy;
        if (month <= 9) m = "0" + month;
        else m = month;
        if (day <= 9) dy = "0" + day;
        else dy = day;
        return `${year}-${m}-${dy}`;
      } else {
        return null;
      }
    },
    getformatDate(value) {
      if (value) {
        return moment(String(value)).format("hh:mm a");
      }
    },
    setFinalFileData(number) {
      if (this.search !== "") {
        this.finalFileData = this.searchFinalFileData.slice(
          this.perPage * (number - 1),
          this.perPage * number
        );
        return (this.rows = this.searchFinalFileData.length);
      } else {
        this.finalFileData = this.fileData.slice(
          this.perPage * (number - 1),
          this.perPage * number
        );
        this.rows = this.fileData.length;
      }
    },
    downloadFile(id, jobType, downloadfileType) {
      import("../../../store/constant/file.type").then(({ DOWNLOAD_FILE }) => {
        loadingService.setloader(true);

        this.$store
          .dispatch(DOWNLOAD_FILE, {
            id: id,
            downloadfileType:
              jobType == "Crawled" ? "crawled" : downloadfileType,
          })
          .then(
            () => {
              loadingService.setloader(false);
            },
            error(() => {
              loadingService.setloader(false);
            })
          );
      });
    },
    openProgress() {
      this.$eventBus.$emit("callCreditMethod");
      import("../../../store/constant/file.type").then(({ GET_ALL_FILE }) => {
        loadingService.setloader(true);
        this.$store
          .dispatch(GET_ALL_FILE, store.getters.getApikey)
          .then((data) => {
            if (data && data.length > 0 && !data[0].endTime) {
              setTimeout(() => {
                import("../../../store/constant/file.type").then(
                  ({ GET_ALL_FILE }) => {
                    // loadingService.setloader(true);
                    this.$store
                      .dispatch(GET_ALL_FILE, store.getters.getApikey)
                      .then((data) => {
                        this.fileData = data;
                        this.rows = data.length;
                        this.setFinalFileData(1);

                        // loadingService.setloader(false);
                      });
                  }
                );
              }, 60000);
            }
            this.fileData = data;
            this.rows = data.length;
            this.setFinalFileData(1);
            loadingService.setloader(false);
            this.isModalOpen = true;
            for (let i = 0; i < this.fileData.length; i++) {
              if (
                this.fileData[i].jobStatus != "OK" &&
                this.fileData[i].jobStatus != "Completed"
              ) {
                const regex = /(\d+) of (\d+)/;
                const match = this.fileData[i].jobStatus.match(regex);
                if (match) {
                  let remainingCount = parseInt(match[1]);
                  let enrichTotalCount = parseInt(match[2]);
                  let enrichedCount =
                    Number(enrichTotalCount) - Number(remainingCount);
                  this.progressPercentage = (
                    (enrichedCount / enrichTotalCount) *
                    100
                  ).toFixed(2);
                }
              }
            }
          });
      });
    },
    getEnrichList() {
      this.$eventBus.$emit("callCreditMethod");
      import("../../../store/constant/file.type").then(({ GET_ALL_FILE }) => {
        loadingService.setloader(true);
        this.$store
          .dispatch(GET_ALL_FILE, store.getters.getApikey)
          .then((data) => {
            if (data && data.length > 0 && !data[0].endTime) {
              setTimeout(() => {
                import("../../../store/constant/file.type").then(
                  ({ GET_ALL_FILE }) => {
                    // loadingService.setloader(true);
                    this.$store
                      .dispatch(GET_ALL_FILE, store.getters.getApikey)
                      .then((data) => {
                        this.fileData = data;
                        this.rows = data.length;
                        this.setFinalFileData(1);
                        // loadingService.setloader(false);
                      });
                  }
                );
              }, 60000);
            }
            this.fileData = data;
            this.rows = data.length;
            this.setFinalFileData(1);
            loadingService.setloader(false);
          });
      });
      bindata();
    },
  },
  watch: {
    search: function(newValue) {
      newValue = newValue.trim();
      this.setFilterValue(newValue);
    },
  },

  beforeMount() {
    this.getEnrichList();
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
};
function bindata() {}
</script>

<style>
.search-container {
  width: 100%;
  position: relative;
}
.main h1 {
  font-size: 42px;
  margin-bottom: 20px;
}
.main-table {
  width: 97%;
}
.search-container ul {
  list-style: none;
  display: flex;
  position: absolute;
  top: 17px;
  right: 53px;
}
.search-container input {
  width: 97%;
  padding: 23px;
  border: 1px solid #dfe1e6;
  border-radius: 10px 10px 10px 10px;
  outline: none;
  padding-left: 70px;
}

.search-icon {
  position: absolute;
  left: 30px;
  top: 24px;
}

.table-start {
  width: 100%;
  border: 1px solid #f7f8fa;
  padding: 27px;
  background-color: aliceblue;
  border-radius: 10px 10px 10px 10px;
}
.table-class {
  width: 100%;
  display: flex;
}
.first-table {
  width: 100%;
  line-height: 45px;
}
select#cars {
  background-color: aliceblue;
  border: none;
  font-size: 14px;
  border-radius: 5px 5px 5px 5px;
  padding: 8px 15px 8px 13px;
  outline: none;
}

.search-container li {
  padding-right: 11px;
}
.search-container button {
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  padding: 8px 24px 8px 24px;
  border-radius: 5px 5px 5px 5px;
}

table.first-table th {
  font-size: 13px;
  padding-top: 10px;
  color: #6b778c;
  font-weight: 500;
  border-bottom: 1px solid #a5adba;
}

.first-table i.fa.fa-check {
  color: #20b89e;
  margin-left: 15px;
}

.first-table button {
  border: none;
  background: #20b89e;
  color: #fff;
  margin-left: 11px;
  padding: 4px 27px 4px 27px;
  border-radius: 15px 15px 15px 15px;
}

.next-button {
  width: 97%;
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #a5adba;
}
.main-button i.fa.fa-arrow-left {
  color: #6b778c;
}
.main-button button {
  padding: 0 10px;
  color: #6b778c;
  width: 36px;
  height: 36px;
  text-align: center;
  background: #f0f8ff;
  border: 1px solid #f0f8ff;
  display: inline-block;
  line-height: 2.1;
  vertical-align: top;
  border-radius: 3px;
}
.main-button .page-item.active button {
  background-color: #253858;
  color: #fff;
}
.main-button button.icon {
  padding: 0;
}
.main-button a.one.active {
  background-color: #666;
  color: white;
  padding: 4px;
  margin-right: 10px;
}
.pagination {
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  table.first-table th {
    font-size: 13px;
    padding-top: 10px;
    color: #6b778c;
    text-align: center;
  }

  .first-table td {
    text-align: center;
    font-size: 15px;
  }

  .first-table button {
    margin-left: 0px;
  }
  .first-table i.fa.fa-check {
    margin-left: 0px;
  }
  .main-table {
    display: inline-block;
    width: 100%;
    overflow: auto;
  }

  table.first-table {
    min-width: 550px;
  }
}
#dropdown-dropright > ul {
  box-shadow: #9bafac 3px 9px;
  position: absolute;
  transform: translate3d(79px, 0px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}
.fileType > a:hover {
  background: #20b89e;
}

.view-report-btn a {
  display: flex;
  line-height: 2;
}

.view-report-btn a .svg-img-box svg {
  max-width: 18px;
  max-height: 18px;
  margin-left: 7px;
}
.first-table button.view-report-btn {
  margin-left: 0;
}
.svg-img-box svg {
  max-width: 22px;
}

.modal-content .modal-header h5 {
  font-weight: 600;
}

.modal-content .modal-body p {
  color: #212529;
}

.progress-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.progress-wrapper .progress-bar {
  background-color: #e9ecef;
  border-radius: 50px;
  width: 100%;
  max-width: 80%;
  height: 16px;
}

.progress-wrapper .progress {
  background-color: #007bff;
  border-radius: 0;
  height: 100%;
}

.progress-wrapper span {
  max-width: 20%;
}

.progress_btn {
  line-height: normal;
  height: 32px;
  margin-left: 18px !important;
}
.progress_btn img {
  max-width: 18px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7484%)
    hue-rotate(183deg) brightness(97%) contrast(101%);
}
</style>
